<template>
  <div id="app">
    <header>
      <nav>
        <router-link to="/">Home</router-link>
        <router-link to="/personal">Personal</router-link>
        <router-link to="/team">Team</router-link>
      </nav>
    </header>
    <div class="content">
      <router-view></router-view>
    </div>
    <footer>
      <p>&copy; 2024 Roster Pal. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>

export default {
  methods: {
    navigateToNotes() {
      window.location.href = "https://notes.induwara.me/";
    }
  }
};
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Rubik', sans-serif;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f9;
  color: #333;
}

#app {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.content {
  flex: 1;
  /* This allows the content area to grow */
  padding-bottom: 50px;
  /* Space for the footer */
  padding-top: 50px;
  /* Space for the header */
}

header {
  position: fixed;
  background-color: #6a1b9a;
  color: white;
  padding: 1rem 0;
  text-align: center;
  position: fixed;
  width: 100%;
  /* Full width */
  z-index: 1000;
  /* Ensure it's above other content */
}

nav a {
  color: white;
  margin: 0 1rem;
  text-decoration: none;
  font-weight: bold;
}

.hero {
  background: url('@/assets/Roster_Pal.png') no-repeat center center/cover;
  color: white;
  text-align: center;
  padding: 5rem 1rem;
  position: relative;
}

.hero::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.hero-content {
  position: relative;
  z-index: 1;
}

.hero h1 {
  font-size: 3rem;
  margin: 0;
}

.hero p {
  font-size: 1.25rem;
}

.features {
  display: flex;
  justify-content: space-around;
  padding: 2rem 1rem;
  background-color: #ffffff;
}

.feature {
  text-align: center;
  width: 30%;
  padding: 1rem;
  background-color: #fafafa;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.feature:hover {
  transform: translateY(-5px);
}

.feature i {
  font-size: 3rem;
  color: #6a1b9a;
}

.feature h2 {
  font-size: 1.5rem;
  margin: 0.5rem 0;
}

footer {
  text-align: center;
  padding: 1rem;
  background-color: #333;
  color: white;
  position: fixed;
  /* Change to fixed */
  bottom: 0;
  /* Stick to bottom */
  left: 0;
  /* Align to left */
  right: 0;
  /* Align to right */
  width: 100%;
  /* Full width */
  z-index: 1000;
  /* Ensure it's above other content */
}
</style>
