<template>
    <transition name="fade">
        <div v-if="isVisible" class="modal-overlay">
            <div class="modal">
                <h3 class="modal-title">Select an Option</h3>
                <ul class="option-list">
                    <li v-for="(item, index) in options" :key="index" class="option-item" @click="selectOption(item)">
                        {{ item }}
                    </li>
                </ul>
                <button class="close-button" @click="closeModal">Close</button>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        isVisible: {
            type: Boolean,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        selectOption(option) {
            this.$emit('select', option);
            this.closeModal();
        },
    },
}
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 90%;
    animation: slide-down 0.3s ease;
}

.modal-title {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
}

.option-list {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
}

.option-item {
    padding: 10px;
    margin: 5px 0;
    background: #f0f0f0;
    border-radius: 8px;
    transition: background-color 0.2s ease;
    cursor: pointer;
}

.option-item:hover {
    background: #e0e0e0;
}

.close-button {
    background: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 15px;
    transition: background-color 0.2s ease;
}

.close-button:hover {
    background: #0056b3;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
    {
    opacity: 0;
}

@keyframes slide-down {
    0% {
        transform: translateY(-20px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
</style>