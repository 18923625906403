<template>
    <div class="container">
        <h1>Roster Pal Team Fetcher</h1><br><br>
        <form @submit.prevent="handleSubmit" class="form">
            <v-row>
                <v-col cols="12" md="4">
                    <label for="date">Select Date:</label>
                    <input type="date" v-model="date" required />
                </v-col>

                <v-col cols="12" md="4">
                    <label for="team">Enter Team:</label>
                    <input type="text" v-model="team" required />
                </v-col>

                <v-col cols="12" md="4">
                    <label for="shift">Select Shift:</label>
                    <select v-model="shift" required>
                        <option value="m">Morning</option>
                        <option value="a">Afternoon</option>
                        <option value="n">Night</option>
                    </select>
                </v-col>

                <button type="submit" class="submit-button">Show the team</button>
            </v-row>
        </form>

        <div v-if="result && result.length" class="result">
            <h2>Response Data:</h2>
            <v-chip-group class="d-flex flex-column">
                <v-chip v-for="(value, key) in aboutData" :key="key"
                    :style="{ backgroundColor: '#fdbb05 ', color: 'white' }">
                    {{ value }}
                </v-chip>
            </v-chip-group>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Area</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in result" :key="index"
                        :style="{ backgroundColor: item.role.startsWith('Shift') ? '#A7C7E7' : 'white' }">
                        <td>{{ item.name }}</td>
                        <td>{{ item.role }}</td>
                        <td>{{ item.cap }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <modal-component :isVisible="showModal" :options="options" @close="showModal = false"
            @select="handleSelect"></modal-component>
        <v-snackbar v-model="snackbar" timeout=2000 centered>
            Invalid Data

            <template v-slot:actions>
                <v-btn color="pink" variant="text" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import ModalComponent from './ModalComponent.vue';
export default {
    components: {
        ModalComponent
    },
    data() {
        return {
            date: new Date().toISOString().split("T")[0],
            team: "Industry",
            shift: "m",
            result: null,
            showModal: false,
            aboutData: null,
            options: [],
            snackbar: false
        };
    },
    methods: {
        async handleSubmit() {
            const formattedDate = this.date.replace(/-/g, "/");
            const url = `https://rosterpalb.induwara.me/listMembers/${formattedDate}/${this.team}/${this.shift}`;

            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();

                if (data[0].Verticle) {

                    console.log(data);
                    this.aboutData = data.slice(0).flat()[0]; // Assuming this returns the about object
                    this.result = data.slice(1).flat();
                } else {
                    this.options = this.extractOptions(data); // Extract options based on your logic
                    this.showModal = true; // Show the modal
                    // Handle the case where the response does not match the expected format
                    console.log("Unexpected response format:", response);
                }
            } catch (error) {
                this.snackbar = true
                console.error('Error fetching data:', error);
            }
        },
        shiftLabel(shift) {
            switch (shift) {
                case 'm':
                    return 'Morning';
                case 'a':
                    return 'Afternoon';
                case 'n':
                    return 'Night';
                default:
                    return 'Unknown';
            }
        }, extractOptions(data) {
            // Logic to extract options from the response data
            return data.map(item => item); // Replace 'someProperty' with the relevant property
        },
        handleSelect(option) {
            this.team = option
            this.handleSubmit()
            // console.log("Selected option:", option);
            // Handle the selected option here
        }
    }
};
</script>

<style scoped>
body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f9;
    color: #333;
}

.container {
    flex: 1;
    width: 90%;
    max-width: 1200px;
    margin: 2rem auto;
    padding: 1.5rem;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

h1 {
    text-align: center;
    color: #6a1b9a;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 0.5rem;
    font-weight: bold;
}

input[type="date"],
input[type="text"],
select,
button {
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

button {
    background-color: #6a1b9a;
    color: white;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #5a1282;
}

.result {
    margin-top: 1.5rem;
    padding: 1.5rem;
    border: 1px solid #ddd;
    background-color: #fafafa;
    border-radius: 8px;
}

.result h2 {
    margin-top: 0;
    color: #6a1b9a;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
}

table,
th,
td {
    border: 1px solid #ddd;
    padding: 0.75rem;
    text-align: left;
}

th {
    background-color: #f5f5f5;
}
</style>
