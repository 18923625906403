<template>
    <div id="app">

        <div class="content">
            <section class="hero">
                <div class="hero-content">
                    <h1>Welcome to Roster Pal</h1>
                    <p>Your Ultimate Scheduling Assistant</p>
                </div>
            </section>

            <section class="features">
                <v-container>
                    <v-row>
                        <!-- Personal Roster Card -->
                        <v-col cols="12" sm="4">
                            <v-card class="hoverable d-flex flex-column align-center justify-center"
                                @click="$router.push('/Personal')">
                                <center>
                                    <v-card-title class="text-center">
                                        <v-icon class="mr-2">mdi-calendar</v-icon>
                                        Find the Personal Roster
                                    </v-card-title>
                                    <v-card-text class="text-center">
                                        <p>Find your roster info</p>
                                    </v-card-text>
                                </center>
                            </v-card>
                        </v-col>

                        <!-- Team Roster Card -->
                        <v-col cols="12" sm="4">
                            <v-card class="hoverable d-flex flex-column align-center justify-center"
                                @click="$router.push('/Team')">
                                <center>
                                    <v-card-title class="text-center">
                                        <v-icon class="mr-2">mdi-account-group</v-icon>
                                        Find the Roster Team
                                    </v-card-title>
                                    <v-card-text class="text-center">
                                        <p>Find who will be there today</p>
                                    </v-card-text>
                                </center>
                            </v-card>
                        </v-col>

                        <!-- Sticky Notes Card -->
                        <v-col cols="12" sm="4">
                            <v-card class="hoverable d-flex flex-column align-center justify-center"
                                @click="navigateToNotes">
                                <center>
                                    <v-card-title class="text-center">
                                        <v-icon class="mr-2">mdi-note</v-icon>
                                        Sticky Notes
                                    </v-card-title>
                                    <v-card-text class="text-center">
                                        <p>Access your sticky notes</p>
                                    </v-card-text>
                                </center>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
        </div>

    </div>
</template>

<script>
export default {
    methods: {
        navigateToNotes() {
            window.location.href = "https://notes.induwara.me/";
        }
    }
};
</script>

<style scoped>
html,
body {
    height: 100%;
    margin: 0;
    font-family: 'Courier New', Courier, monospace;
}

body {
    display: flex;
    flex-direction: column;
    background-color: #f4f4f9;
    color: #333;
}

.content {
    flex: 1;
}

header {
    background-color: #6a1b9a;
    color: white;
    padding: 1rem 0;
    text-align: center;
}

nav a {
    color: white;
    margin: 0 1rem;
    text-decoration: none;
    font-weight: bold;
}

.hero {
    background: url('@/assets/Roster_Pal.png') no-repeat center center/cover;
    color: white;
    text-align: center;
    padding: 5rem 1rem;
    position: relative;
}

.hero::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.hero-content {
    position: relative;
    z-index: 1;
}

.hero h1 {
    font-size: 3rem;
    margin: 0;
}

.hero p {
    font-size: 1.25rem;
}

.features {
    display: flex;
    justify-content: space-around;
    padding: 2rem 1rem;
    background-color: #ffffff;
}

.feature {
    text-align: center;
    width: 30%;
    padding: 1rem;
    background-color: #fafafa;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.feature:hover {
    transform: translateY(-5px);
}

.feature i {
    font-size: 3rem;
    color: #6a1b9a;
}

.feature h2 {
    font-size: 1.5rem;
    margin: 0.5rem 0;
}

footer {
    text-align: center;
    padding: 1rem;
    background-color: #333;
    color: white;
    position: sticky;
    bottom: 0;
}
</style>